<template>
    <div>
      <TextBox
        :isTitle="true"
        :cefrLevel="payload.cefr_level"
        :label="'Question title'"
        :placeholder="'Insert Question title here'"
        v-model="title"
      />
      <GapfillTextarea
        v-model="gapfill_paragraph"
        :answer="question.question_options[0].value"
        @updateAnswer="setAnswer"
        :placeholder="'Insert Gapfill text here'"
        :cefrLevel="payload.cefr_level"
        :label="'Content text'"
      />
      <OptionsRadio
        v-model="question"
        :noQuestion="true"
        :cefrLevel="payload.cefr_level"
        :last="true"
      />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from '../../../components/item/TextBox'
import GapfillTextarea from '../../../components/item/GapfillTextarea'
import OptionsRadio from '../../../components/item/OptionsRadio'
import { v4 as uuidv4 } from 'uuid'

export default {
    data: () => ({
        title: '',
        gapfill_paragraph: '',
        question: {
          question: '',
          question_id: uuidv4(),
          solution: [''],
          question_options:[
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
          ],
        },
        questions:[],
    }),
    computed: {
        ...mapState('item', ['payload', 'isUpdate']),
    },
    methods: {
        setAnswer(value) {
          const question = this.question;
          question.question_options[0].value = value
          this.question = question
        },
        onSubmit() {
          // When creating an item, set the question's solution[id] to be the option_id of the first question_option
          if (!this.isUpdate) this.question.solution[0] = this.question.question_options[0].option_id

          const data = {
            template_type: 'MCQ_GAPFILL_PARAGRAPH',
            static_text: 'Choose the best words to complete the text.',
            title: this.title,
            gapfill_paragraph: this.gapfill_paragraph,
            questions: [this.question]
          }
          this.$store.commit('item/setPayload', data)
        }
    },

    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.title = this.payload.title
        this.gapfill_paragraph = this.payload.gapfill_paragraph

        // Make sure that the solution is first in the list of question options
        const { question, question_id, question_options, solution } = this.payload.questions[0];
        const solutionIndex = question_options.findIndex(option => option.option_id === solution[0]);
        const solutionOption = question_options[solutionIndex];
        question_options.splice(solutionIndex, 1);
        question_options.unshift(solutionOption);

        this.question = {
          question,
          question_id,
          question_options,
          solution,
        }
      }
    },

    components:{
      TextBox,
      GapfillTextarea,
      OptionsRadio
    },

    watch: {
    }
}

</script>

<style lang="scss" scoped>

.title
{
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  border: unset;
  border-bottom: 3pt solid rgb(222, 222, 222);
}


.full-para
{

  flex-wrap: wrap;
  padding: 3pt;
  gap: 10pt;
  max-width: 500pt;
  overflow: hidden;
  word-wrap: normal;
  overflow-wrap: break-word;

}



.options
{
  display: flex;
  flex-direction: column;
  gap: 10;
}


</style>